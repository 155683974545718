<template>
  <div class="mb-7">
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="false"
      :addable="true"
      addButtTitle="Add Alternative Network"
      :templateContent="result"
    ></lineFieldsArray>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";

export default {
  components: { lineFieldsArray },
  data() {
    return { operatorList: [], rerunsObject: {} };
  },
  props: ["field", "fieldAttributes", "result","value","isValid"],

  computed: {
    fields() {
      let fields = {
        country: {
          type: "combobox",
          options: this.result["additional"]["rattuxCountries"],
          name: "Country",
          sort: false,
          fieldAttrInput: { class: "" },
        },
        operator: {
          type: "select",
          inputFieldFormatter: "operatorFormatter",

          name: "Operator",
          default: "0",
        },
        rattype: {
          type: "select",
          inputFieldFormatter: "rattypeFormatter",

          name: "RAT Type",
          default: "",
        },
        remove: {
          inputFieldFormatter: "networkTrash",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-1",
          },
        },
      };
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [{ country: "", operator: "0", rattype: "" }];

        if (typeof this.value != "undefined") {
          pairArray = this.value;
        }
        return pairArray;
      },
    },
  },
  methods: {
    checkInput(value) {
      if (value.length == 0) {
        value.push({ country: "", operator: "0", rattype: "" });
      }

      this.$emit("input", value);
    },
  },
};
</script>